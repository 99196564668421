body {
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #318E94;
  border-radius: 10px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hero {
  /* background-image: url("../Images/programmer-and-developer-teamwork-2022-02-10-20-35-17-utc\ 1.png"); */
  height: 100vh;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.hero .swiper-slide {
  background-image: url("../Images/programmer-and-developer-teamwork-2022-02-10-20-35-17-utc\ 1.png");
  height: 100vh;
}

.hero .swiper-pagination-bullet {
  background-color: #FFF;
}

/* .hero .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  left: -85%;
  top: -10%;
  transform: rotate(90deg);
} */

.hero .layout {
  background-color: rgba(0, 0, 0, 0.322);
  padding: 2rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.hero .layout h1 {
  font-size: 90px;
  font-weight: bold;
  color: #FFF;
}

.hero .layout p {
  font-size: 20px;
  color: #FFF;
  margin-bottom: 1rem;
}

.mouse {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 2rem;
  cursor: pointer;
  position: absolute;
  bottom: 4rem;
  left: 50%;
}

.mouse .mouse-btn {
  margin: 10px auto;
  width: 25px;
  height: 50px;
  border: 3px solid #FFF;
  border-radius: 50px;
  display: flex;
}

.mouse .mouse-btn .mouse-scroll {
  display: block;
  width: 10px;
  height: 10px;
  background: #318E94;
  border-radius: 50%;
  margin: auto;
  animation: scrolling 1s linear infinite;
}

@keyframes scrolling {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}

.services {
  padding: 7rem 0;
}

.services .col-md-6 {
  display: flex;
}

.main-heading {
  font-size: 36px;
  font-weight: bold;
  color: #242A2A;
  margin-bottom: .6rem;
}

.main-description {
  font-size: 16px;
  color: #5B6268;
  margin-bottom: 1.8rem;
  width: 40%;
}

.card {
  border-radius: 8px;
  margin-bottom: 1.5rem;
  width: 100%;
}

.card img {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),  lightgray 50% / cover no-repeat;
  height: 240px;
  width: 100%;
}

.card-body {
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}

.card-body h3 {
  font-size: 20px;
  color: #242A2A;
  font-weight: bold;
  margin-bottom: 1rem;
}

.card-body p {
  color: #5B6268;
  font-size: 14px;
  margin-bottom: 1rem !important;
}

.card-body a {
  color: #318E94;
  font-size: 16px;
}

.card-body a i {
  margin-left: .4rem;
}

.about {
  padding: 0 0 7rem 0;
}

.about-card {
  border-radius: 8px;
  background: url("../Images/programmer-and-developer-teamwork-2022-02-10-20-35-17-utc\ 1.png") rgba(211, 211, 211, 0.151) 50% / cover no-repeat;
}

.about-layout {
  padding: 7rem 5rem;
  background-color: rgba(0, 0, 0, 0.219);
}

.about-tools {
  border-radius: 8px;
  border: 1px solid var(#E1E4ED, #E1E4ED);
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(2px);
  padding: 3rem;
  width: 60%;
  transition: all .3s;
}

.about-tools:hover {
  background: rgba(255, 255, 255, 1);
  transition: all .3s;
}

.about h2 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0.8rem;
}

.about p {
  font-size: 16px;
  margin-bottom: 1.2rem;
}

.about a {
  color: #318E94;
  font-size: 16px;
}

.about a i {
  margin-left: .4rem;
}

.lab {
  margin-bottom: 5rem;
  padding: 3rem 0;
  background-color: #EAF4F4;
}

.dental-lab {
  background-image: url("../Images/Image\ BG\ \(1\).png");
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.dental-layout {
  display: flex;
  justify-content: end;
  align-items: start;
  flex-flow: column;
  width: 100%;
  height: 100%;
  background-color: #0000002c;
  padding: 1.5rem;
  border-radius: 10px;
}

.dental-lab h3 {
  font-size: 36px;
  font-weight: bold;
  color: #FFF;
  margin-bottom: 0.2rem;
}

.dental-lab span {
  font-size: 16px;
  color: #FFF;
}

.number-showcase {
  border-radius: 10px;
  border: 1px solid #E1E4ED;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  padding: 1rem;
  display: flex;
  align-items: center;
}

.number-showcase img {
  margin-right: 2rem;
  width: 40px;
  height: 40px;
}

.number-showcase h3 {
  font-size: 36px;
  font-weight: bold;
  color: #318E94;
  margin-bottom: 0.2rem;
}

.number-showcase span {
  font-size: 16px;
  color: #838E8F;
}

.number-showcase img {
  margin-right: 2rem;
}

.partner {
  background: #ECF0F1;
  padding: 3rem 0;
}

.lab-logo {
  padding: 0.6rem 1.2rem;
  border-radius: 10px;
  border: 1px solid #E1E4ED;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  display: flex;
  align-items: center;
}

.lab-logo img {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
} 

.lab-logo h3 {
  color: #6D758F;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.partner .swiper-next i,
.partner .swiper-prev i {
  color: #6D758F;
  font-size: 22px;
  cursor: pointer;
}

.partner {
  position: relative;
}

.partner .swiper-buttons {
  /* width: 100%; */
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 100%; */
  z-index: 2;
  top: 72%;
  left: 5%;
  right: 5%;
}

.contact {
  padding: 5rem 0;
}

.contact-card {
}

.contact-layout {
  padding: 7rem 1rem;
  border-radius: 15px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url("../Images/programmer-and-developer-teamwork-2022-02-10-20-35-17-utc\ 1.png") lightgray 50% / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
  width: 100%;
  height: 100%;
}

.contact h2 {
  color: #FFF;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contact a {
  background-color: #FFF;
  border: 1px solid #FFF;
  border-radius: 4px;
  color: #242A2A;
  font-size: 18px;
  padding: .6rem 2rem;
  transition: all .3s;
}

.contact a:hover {
  background-color: transparent;
  color: #FFF;
  border: 1px solid #FFF;
  transition: all .3s;
}

.join-nav .navbar,
.join-nav footer {
  display: none;
}

.hero-services {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-image: url("../Images/Frame\ 1\ \(1\).png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.service {
  padding: 5rem 0;
  border-bottom: 1px solid #AAD0D3;
}

.card-service {
  /* border-radius: 8px; */
  /* border: 1px solid #E1E4ED; */
  /* background: #ECF0F1; */
  /* box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08); */
  /* padding: 1rem; */
  /* margin-bottom: 1rem; */
  /* transition: all .3s; */
  cursor: pointer;
}

.card-service h2 {
  color: #242A2A;
  font-size: 18px;
  font-weight: bold;
  transition: all .3s;
}

.card-service p {
  color: #5B6268;
  font-size: 14px;
  margin-bottom: 0;
  transition: all .3s;
}

/* .card-service:hover {
} */

.card-service:hover h2 {
  color: #FFF;
  transition: all .3s;
}

.card-service:hover p {
  color: #FFF;
  transition: all .3s;
}

/* .SUBSERVICES {
  padding: 5rem 0;
} */

.card-SUBSERVICES {
  border-radius: 10px;
  border: 1px solid var(--Neutral-colors-400, #E1E4ED);
  background: var(--Neutral-colors-100, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 90px;
  transition: all .3s;
  margin-bottom: 1rem;
}

.card-SUBSERVICES img {
  width: 50px;
  height: 50px;
  margin: 0 .3rem;
  border-radius: 50%;
  border: 1px solid #318E94;
}

.card-SUBSERVICES span {
  font-size: 16px;
  color: #6D758F;
  font-weight: bold;
}

.card-SUBSERVICES p {
  color: #8692A6;
  font-size: 12px;
  margin: 0;
}

.card-SUBSERVICES:hover img {
  width: 60px;
  height: 60px;
  border: 1px solid #318E94;
  transition: all .3s;
}

.card-SUBSERVICES:hover span {
  color: #318E94;
}

.contact-us {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
}

.layout-contact {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-contact {
  background-color: #1D565A;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-flow: column;
  height: 100%;
  padding: 2rem;
}

.form-contact {
  padding: 2rem;
}

.card-contact h1 {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.card-contact p {
  color: #C9FFD8;
  font-size: 16px;
}

.card-contact a {
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 0.6rem;
  display: block;
}

.card-contact a i {
  color: #FFFFFF;
  margin-right: 1rem;
}

.card-contact ul li a {
  color: #308D94;
  background-color: #FFF;
  margin-right: 1rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.card-contact ul li a i {
  color: #308D94;
  margin: 0;
}

.card-contact ul {
  display: flex;
  align-items: center;
}

.active-nav .navbar {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    transition: all .3s !important;
    z-index: 1024 !important;
    background-color: #FFF;
    padding: 1rem 0;
}

.active-nav .nav-links ul li a {
    color: #838E8F;
}

.labs {
  padding: 5rem 0;
}

.card-lab {
  margin-bottom: 1rem;
  transition: all .3s;
  position: relative;
}

.card-lab img {
  width: 100%;
  max-height: 210px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
}

.card-lab h2 {
  color: #242A2A;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.3rem;
  transition: all .3s;
  display: flex;
  align-items: center;
}

.card-lab h2 img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.6rem;
}

.card-lab p {
  color: #5B6268;
  font-size: 16px;
}

.card-lab:hover h2 {
  color: #308D94;
  transition: all .3s;
}

.load-more {
  border-radius: 6px;
  border: 1px solid #308D94;
  color: #308D94;
  font-size: 16px;
  padding: .6rem 2rem;
  transition: all .3s;
  background-color: transparent;
}

.load-more:hover {
  border-radius: 6px;
  border: 1px solid #308D94;
  color: #FFF;
  font-size: 16px;
  padding: .6rem 2rem;
  transition: all .3s;
  background-color: #308D94;
}

.rate {
  display: flex;
  align-items: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #000;
}

.rate svg {
  margin: 0 .4rem;
}

.service .react-tabs__tab-list {
  border-bottom: 1px solid #AAD0D3 !important;
  padding-bottom: 3rem !important;
}

.service .react-tabs__tab--selected {
  border-radius: 8px;
  border: 1px solid #E1E4ED !important;
  background: #308D94 !important;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  border-radius: 8px !important;
}

.service .react-tabs__tab--selected h2 {
  color: #FFF;
}

.service .react-tabs__tab--selected p {
  color: #FFF;
}

.service .react-tabs__tab-list li {
  border-radius: 8px;
  border: 1px solid #E1E4ED;
  background: #ECF0F1;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  padding: 1rem;
  margin-bottom: 1rem;
  transition: all .3s;
  width: 30%;
  margin: 0 1.6666666%;
}

.service .react-tabs__tab-list li:hover {
  background: #308D94;
  box-shadow: 0px 2px 4px 0px rgba(25, 33, 61, 0.08);
  transition: all .3s;
}

.service h4 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.profile .react-tabs {
  height: 100%;
  width: 100%;
  display: flex;
}

.my-profile {
  width: calc(100% - 220px);
  display: flex;
  /* justify-content: center; */
  flex-flow: column;
  padding-top: 8rem;
}

.profile {
  background-color: #F6F7F8;
}

.card-profile {
  border-radius: 8px;
  background: #FFF;
  padding: 2rem;
}

.my-profile h2 {
  color: #87BDC1;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.side-bar {
  height: 100vh ;
  width: 220px;
  display: flex;
  padding-top: 8rem;
  /* justify-content: center; */
  flex-flow: column;
  background-color: #F1F3F7;
}

.side-bar h2 {
  color: #838E8F;
  font-size: 14px;
  margin-bottom: 1rem;
  padding: .6rem 1rem;
  border-left: 3px solid transparent;
}

.side-bar h2 a {
  color: #FA6767;
  font-size: 18px;
}

.side-bar i {
  margin: 0 1rem;
}

.profile .react-tabs__tab--selected {
  background-color: transparent !important;
  border: none !important;
}

.profile .react-tabs__tab--selected h2 {
  color: #308D94;
  font-weight: bold;
  border-left: 3px solid #318E94;
}

.react-tabs__tab {
  padding: 0 !important;
}

.profile h3 {
  color: #242A2A;
  font-size: 20px;
  margin-bottom: 3rem;
}

.edit {
  /* width: 100%; */
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .6rem 2rem;
  background-color: transparent;
  border:  1px solid #308D94;
  color: #308D94;
  font-size: 16px;
  border-radius: 6px;
}

.history {
  border-radius: 8px;
  background: #FFF;
  padding: 0.6rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;
}

.history h3 {
  margin-bottom: 0;
}

.history h4 {
  margin-bottom: 0;
  font-size: 16px;
  color: #5B6268;
}

.history p {
  margin-bottom: 0;
  color: #838E8F;
  font-size: 14px;
}

.history span {
  margin-bottom: 0;
  color: #838E8F;
  font-size: 14px;
}

.history img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin: 0 1rem;
}

.profile-tab .labs h3 {
  margin-bottom: 0;
}

.hero-about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-image: url("../Images/Frame\ 1\ \(1\).png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-about h1  {
  font-size: 34px;
  color: #308D94;
  border-left: 5px solid #308D94;
  padding: 1.5rem;
  border-radius: 10px 1px 1px 10px;
  margin-bottom: 1.5rem;
}

.hero-about p {
  color: #EAF4F4;
  font-size: 16px;
  width: 70%;
}

.mission {
  background-color: #EAF4F4;
  padding: 5rem 0;
  margin-bottom: 5rem;
}

.mission .vision {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.mission h1 {
  font-size: 40px;
  color: #242A2A;
  margin-bottom: 3rem;
}

.vision h2 {
  font-size: 90px;
  color: #AAD0D359;
  margin-right: 1rem;
}

.vision h3 {
  font-size: 24px;
  color: #242A2A;
  margin-bottom: .5rem;
}

.vision p {
  font-size: 16px;
  color: #5B6268;
}

.choose {
  padding: 5rem 0;
}

.choose span {
  border-radius: 150px;
  background: #EAF4F4;
  font-size: 12px;
  color: #1D565A;
  padding: .2rem .5rem;
}

.benefit {
  padding: 1rem 2rem;
  border-radius: 10px;
  background: #ECF0F1;
  margin-bottom: 1.5rem;
}

.benefit h3 {
  color: #226368;
  font-weight: bold;
  margin-bottom: .5rem;
  font-size: 20px;
}

.benefit p {
  color: #6D758F;
  font-size: 16px;
  margin: 0;
}

.benefit img {
  border-radius: 12px;
  background-color: #FFF;
  width: 35px;
  height: 35px;
  margin-bottom: .5rem;
  padding: 8px;
}

.choose-img img {
  border-radius: 15px;
  width: 100%;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
}

.single-lab-details {
  height: 60vh;
}

.single-lab-details img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-lab-details .swiper-slide {
  height: 60vh;
}

.single-lab-details .swiper-slide .img-layout {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  transition: all .3s;
}

.single-lab-details .swiper-slide-prev .img-layout,
.single-lab-details .swiper-slide-next .img-layout {
  display: block;
  transition: all .3s;
}

.lab-details-logo {
  padding: 2rem 0;
}

.lab-details-logo img {
  border: 3px solid #ECF0F1;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 0 1rem;
}

.lab-details-logo h2 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0;
  color: #242A2A;
}

.lab-details-logo span {
  font-size: 14px;
  color: #7A8593;
}

.lab-details-logo p {
  font-size: 14px;
  color: #6D758F;
  margin: 1rem 0;
}

.order button {
  border-radius: 6px;
  background: #308D94;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  border: none;
  padding: .6rem 2.6rem;
  margin-right: 1.5rem;
}

.lab-details-logo .order h3 {
  font-size: 14px;
  color: #838E8F;
  margin: 0;
}

.lab-details-logo .order strong {
  font-size: 14px;
  color: #242A2A;
  margin: 0;
}

.lab-details-logo .order {
  display: flex;
  align-items: center;
}

.location {
  padding: 5rem 0;
}

.location-card {
  border-radius: 10px;
  background: #ECF0F1;
  padding: .6rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-card h2 {
  font-size: 32px;
  color: #242A2A;
  margin-bottom: 1rem;
}

.location-card p {
  color: #838E8F;
  font-size: 16px;
}

.location-card p i {
  margin-right: 1rem;
}

.location-card button {
  border-radius: 6px;
  background: #308D94;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  border: none;
  padding: .6rem 2.6rem;
  margin-top: 2.5rem;
}

.location-card iframe {
  width: 100%;
}

.view {
  border-radius: 16px;
  background: #F2F2F2;
  padding: 1rem;
}

.view h2 {
  color: #242A2A;
  margin-bottom: 1rem;
  font-size: 22px;
  font-weight: bold;
}

.view p {
  color: #717878;
  margin-bottom: .5rem;
  font-size: 16px;
}

.view span {
  color: #7A8593;
  font-size: 14px;
}

.review {
  padding: 5rem 0;
}

.review h1 {
  color: #242A2A;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 2rem;
}

.review .review-prev,
.review .review-next {
  color: #838E8F;
  margin: 0 1rem;
  cursor: pointer;
}


@media (min-width: 576px) {
  .progress-wrap.active-progress {
      opacity: 1;
  }
}
.progress-wrap {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 3.3rem;
  height: 3.3rem;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 1010;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition: all 200ms linear, margin-right 0ms;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap {
      transition: none;
  }
}
.progress-wrap.active-progress {
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap:after {
  position: absolute;
  font: var(--fa-font-solid);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f062";
  text-align: center;
  line-height: 3.3rem;
  font-size: 1.2rem;
  color: #308D94;
  left: 0;
  top: 0;
  height: 3.3rem;
  width: 3.3rem;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap:after {
      transition: none;
  }
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: #308D94;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap svg.progress-circle path {
      transition: none;
  }
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}

.RSPBprogressBar {
  height: 2px !important;
  background-color: #838E8F !important;
}

.indexedStep {
  width: 30px !important;
  height: 30px !important;
  background-color: #838E8F !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.indexedStep.accomplished {
  background-color: #1DBF6E !important;
}

.RSPBprogressBar .RSPBprogression {
  background-color: #1DBF6E !important;
}

.card-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: transparent;
  border: none;
}

form .card {
  border: none;
}

.continue {
  background-color: #308D94;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  padding: .6rem 2rem;
  border-radius: 8px;
}

.back-form {
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #676767;
  padding: .6rem 2rem;
}

.continue:hover {
  background-color: #308D94;
  color: #FFF;
}

.back-form:hover {
  background-color: transparent;
  color: #676767;
}

.modal-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.modal-service .nav-item button {
  border-radius: 8px;
  border: 1px solid #E1E4ED;
  background: #ECF0F1;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  padding: 1rem;
  margin-bottom: 1rem;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  transition: all .3s;
}

.modal-service .nav-item button.active {
  border: 1px solid #308D94;
  background: #308D94;
  box-shadow: 0px 2px 4px 0px rgba(25, 33, 61, 0.08);
  color: #FFF;
}

.modal-lg .input-file button img {
  background: transparent;
}

.payment {
  padding: 7rem 0;
}

.card-payment {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  margin-bottom: 2rem;
}

.card-payment h2 {
  font-size: 22px;
  color: #242A2A;
  border-bottom: 1px solid #242424;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.card-payment p {
  font-size: 16px;
  margin-bottom: 0;
  color: #242A2A;
}

.card-payment h3 {
  font-size: 18px;
  font-weight: normal;
  color: #242A2A;
  margin-bottom: 0.5rem;
}

.card-payment i {
  color: #AAD0D3;
  margin: 0 .3rem;
}

.order-card {
  border-radius: 8px;
  background: #F2F2F2;
  padding: 2rem;
  /* height: 100%; */
}

.order-card h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  color: #242A2A;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.order-card h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #242A2A;
  margin-bottom: .7rem;
}

.order-card h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  color: #242A2A;
}

.order-card h4 {
  font-size: 14px;
  color: #B2B2B2;
}

.order-card h4 span {
  font-size: 20px;
  color: #242424;
}

.order-card .order-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  margin-top: 2rem;
}

.order-card .order-buttons button {
  width: 100%;
  margin-bottom: 1rem;
}

.success {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column;
}

.success h2 {
  color: #242A2A;
  margin: 2rem 0;
  font-size: 32px;
  font-weight: bold;
}

.success .back-order {
  border-radius: 8px;
  background: #F2F2F2;
  font-size: 16px;
  font-weight: bold;
  color: #A4A4A4;
  border: none;
  padding: 0.5rem;
  width: 50%;
  margin: 0.3rem;
}

.count-card .from-group {
  display: flex;
  align-items: start;
  flex-flow: column;
}

.count-card .from-control {
  padding: 1rem;
  border-radius: 12px;
  width: 100%;
  margin-top: .4rem;
  border: 1px solid #1D565A;
  background: #FFF;
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.08);
}

.cancel-order {
  border-radius: 8px;
  background: #FA6767;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  /* width: 50%; */
  border: none;
  padding: 0.5rem;
  margin: 0.3rem;
}

.modal-header {
  background-color: transparent !important;
  border: none !important;
}

.fa-yellow {
  color: #EDF051;
}

.lab-details-logo ul {
  display: flex;
  align-items: center;
}

.tooth {
  text-align: center;
}

.tooth svg path.tooth-path:hover {
  fill: #A6F6B7 !important;
  stroke: #A6F6B7 !important;
  cursor: pointer;
}

.tooth svg path.tooth-path.active {
  fill: #A6F6B7 !important;
  stroke: #A6F6B7 !important
}

.search-services {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-image: url("../Images/71cb31d896.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-flow: column;
}

.search-services h1 {
  color: #FFFFFF;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 2rem;
}

.search-services p {
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 1rem;
}

.search-services .form-group {
  display: flex;
  align-items: center;
}

.search-services .form-group input {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #7A7D85;
  background: #FFF;
  padding: .7rem 2rem;
}

.search-services .form-group button {
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #318E94;
  background: #318E94;
  padding: .6rem 1.6rem;
  color: #FFF;
  font-size: 18px;
}

.freelance-tabs .react-tabs__tab {
  border: none !important;
  margin-bottom: 1rem;
}

.freelance-tabs .react-tabs__tab h2 {
  font-size: 15px;
  color: #606161;
  border: none;
  background-color: transparent;
  font-weight: bold;
  border-radius: 6px;
  padding: .6rem 2rem;
  margin: 0 1rem;
}

.freelance-tabs .react-tabs__tab--selected h2 {
  background-color: #318E94;
  color: #FFF;
}

.freelance-tabs {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.crown {
  border: 1px solid #1D565A;
  border-radius: 8px;
  padding: 4rem 0;
  width: 100%;
  margin: 0 1rem;
}

.crown h3 {
  color: #1D565A;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0;
  transition: all .3s;
}

.crown-tabs ul {
  width: 100%;
  text-align: center;
  display: flex !important;
  justify-content: space-between;
}

.crown-tabs .react-tabs__tab {
  width: 100%;
  text-align: center;
  display: flex !important;
  border-radius: 8px;
}

.crown-tabs .react-tabs__tab.react-tabs__tab--selected {
  background-color: #1D565A;
  transition: all .3s;
  border-radius: 8px;
}

.crown-tabs .react-tabs__tab--selected h3 {
  color: #FFF;
  transition: all .3s;
}

.crown-tabs h4 {
  font-size: 36px;
  font-weight: bold;
  margin: 3rem 0;
  color: #000;
  text-align: center;
}

.count {
  width: 100%;
  margin: 1rem;
}

.num-of-counter {
  width: 100%;
  border-radius: 4px;
  background: rgba(244, 248, 250, 0.50);
  padding: .4rem 2rem;
  text-align: center;
}

.num-of-counter span {
  color: #87BDC1;
  font-size: 20px;
  /* font-weight: bold; */
}

.price {
  text-align: center;
  margin: 1rem 0;
}

.price h3 {
  color: #5B6268;
  font-size: 18px;
  margin: 0;
}

.price p {
  color: #318E94;
  font-size: 18px;
}

.disable {
  color: #A4A4A4 !important;
}

.price span {
  color: #318E94;
  font-size: 18px;
  margin: 0;
}

.counter {
  border-radius: 4px;
  background: #F4F8FA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .4rem;
}

.counter button {
  color: #000;
  font-size: 24px;
  background-color: transparent;
  border: none;
}

.counter span {
  color: #000;
  font-size: 18px;
  margin: 0;
}

.counter-card {
  /* display: flex; */
  align-items: center;
}

.count-card {
  text-align: center;
}

.count-card h5 {
  border-radius: 12px;
  background: rgba(135, 189, 193, 0.20);
  padding: .8rem 2rem;
  color: #000000;
  font-size: 26px;
  font-weight: bold;
  margin: 3rem 0;
  display: inline-block;
}

.count-card h5 span {
  padding: .3rem;
  background-color: #FFF;
  color: #308D94;
  font-size: 26px;
  font-weight: bold;
  border-radius: 4px;
  margin-left: 0.6rem;
}











@media (width <= 768px) { 
  .layout-contact {
    height: 100%;
    padding: 7rem 0;
  }
  .profile .react-tabs__tab--selected h2 {
    color: #308D94;
    font-weight: bold;
    border-left: none;
    margin-bottom: 0;
    border-bottom: 3px solid #318E94;
  }
  .hero .layout h1 {
    font-size: 45px;
  }
  .side-bar h2 {
    margin-bottom: 0;
    font-size: 16px;
  }
  .react-tabs {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
  }
  .my-profile {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    flex-flow: column;
    padding-top: 2rem;
  }
  .side-bar {
    height: 100% ;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
    flex-flow: row;
  }
  .react-tabs__tab-list li p {
    display: none;
  }
  .react-tabs__tab-list li h2 {
    margin: 0;
    font-size: 16px;
  }
  .number-showcase {
    margin-bottom: 1.5rem;
  }
  .about-tools {
    padding: 1rem;
  }
  .about-layout {
    padding: 3rem;
  }
  .about-tools {
    width: 100%;
  }
  .layout h1 {
    font-size: 45px;
  }
  .main-description {
    width: 100%;
  }
  .history {
    flex-flow: column;
  }
}