.join {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.join img {
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.join-us {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 100%;
    width: 70%;
    margin: auto;
    flex-flow: column;
    position: relative;
}

.join h1 {
    font-size: 24px;
}

.join-us p {
    width: 70%;
    color: #838E8F;
}

.student {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    position: relative;
    overflow: hidden;
    transition: all .3s;
    border: 1px solid transparent;
}

.student h3 {
    color: #5B6268;
    font-size: 16px;
    margin-bottom: 0;
    transition: all .3s;
}

.student p {
    color: #838E8F;
    font-size: 14px;
    margin-bottom: 0;
    width: 80%;
}

.student i {
    position: relative;
    right: -100%;
    color: #308D94;
    transition: all .3s;
}

.student:hover {
    border: 1px solid#308D94;
    background: #EAF4F4;
    box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);
}

.student:hover svg {
    fill: #308D94;
    stroke: #308D94;
}

.student:hover svg path {
    fill: #308D94;
    stroke: #308D94;
}

.student:hover h3 {
    font-weight: bold;
    color: #308D94;
    transition: all .3s;
}

.student:hover i {
    position: relative;
    right: 0%;
    transition: all .3s;
}

.join h4 {
    font-size: 18px;
    font-weight: normal;
    color: #5B6268;
    margin-top: 3rem;
}

.join h4 a {
    color: #308D94;
    font-weight: bold;
}

.join-img {
    width: 50%;
}

.join-div {
    width: 50%;
}

.form-group {
    margin-bottom: 1rem;
}

.form-label,
.form-check-label {
    font-size: 14px;
    margin-bottom: 0.3rem;
    color: #696F79;
}

.form-control {
    border-radius: 6px;
    border: 1px solid #1D565A;
    background: #FFF;
    box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.08);
}

.form-check-input:checked {
    background-color: #308D94;
    border-color: #308D94;
}

.Continue {
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .6rem;
    background-color: #308D94;
    color: #FFF;
    font-size: 16px;
    border-radius: 6px;
}

#upload_input {
    font-size: 50px;
    width: 100%;
    opacity: 0; 
    filter:alpha(opacity=0);  
    position: relative; 
    top: -40px;
}

.input-file {
    display: block; 
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.input-file button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96%;
    position: relative; 
    top: 40px; 
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.06);
    border: none;
    left:2%;
    cursor: pointer;
}

.input-file button img {
    width: 34px;
    height: 34px;
    margin: 1rem;
}

.input-file button span {
    font-size: 14px;
    color: #494949;
}

.back {
    font-size: 14px;
    color: #8692A6;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0rem;
    top: 2rem;
}

.back i {
    font-size: 18px;
    margin-right: 1rem;
}







@media (width <= 991px) {
    .join img {
        display: none;
    } 
    .join-img {
        width: 0%;
    }
    .join-div {
        width: 100%;
    }
}