footer {
    border-top: 1px solid #F1F3F7;
    background: #242A2A;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 0;
    /* border-bottom: 1px solid #E1E4ED; */
}

.footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-links ul li a {
    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    margin: 0 1.3rem;
}

.footer-links ul li a:hover {
    color: #87BDC1;
}

.footer-social ul li a i {
    background-color: #838E8F;
    border: 1px solid #838E8F;
    color: #FFF;
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-left: 0.7rem;
    font-size: 15px;
    transition: all .3s;
}

.footer-social ul li a i:hover {
    border: 1px solid #838E8F;
    background-color: transparent;
    color: #308D94;
    transition: all .3s;
}

.copyRight {
    border-top: 1px solid #E1E4ED;
    padding: 1.5rem 0;
}

.copyRight p {
    color: #6D758F;
    font-size: 14px;
    text-align: center;
    margin: 0;
}

.copyRight p a {
    color: #308D94;
    font-size: 14px;
    text-align: center;
    margin: 0;
}

@media (width <= 1024px) { 
    .footer {
        flex-flow: column;
    }

    .footer-links {
        margin: 1.5rem 0;
    }
}

@media (width <= 768px) { 
    .footer-links ul li a {
        font-size: 12px;
        margin: 0 .5rem;
    }
}