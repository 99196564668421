.navbar {
    padding: 0.7rem 0;
    border-bottom: 1px solid rgba(96, 97, 97, 0.40);
    background: #FAFBFC;
    position: absolute;
    top: 0;
    z-index: 1024;
    width: 100%;
    background-color: transparent;
}

.header-home2 {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    transition: all .3s !important;
    z-index: 1024 !important;
    background-color: #FFF;
    padding: 1rem 0;
}

.header-home2 .nav-links ul li a {
    color: #838E8F;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-barnd img {
    max-width: 180px;
}

.nav-links ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-links ul li a {
    /* color: #838E8F; */
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0 .8rem;
    padding: 0;
}

.language-select {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: #FFF;
    margin-left: 0.5rem;
}

.language-select option {
    color: #000000;
}

.header-home2 .language-select {
    color: #000;
}

.nav-links ul li a:hover {
    color: #1D565A;
}

.nav-links ul li a.active {
    color: #1D565A;
    font-weight: bold;
}

.profile-button {
    border-radius: 8px;
    background: #318E94;
    border: none;
    padding: .5rem 1rem;
    margin-left: 1rem;
    color: #FFF;
    font-size: 15px;
    font-weight: bold;
}

.profile-button i {
    margin-right: 0.5rem;
}

.bars i {
    color: #FFF;
    font-size: 24px;
    margin-left: 1rem;
    margin-right: 1rem;
    display: none;
}

.nav-buttons {
    display: flex;
    align-items: center;
}

.modal-backdrop.show {
    opacity: 0;
    z-index: -1;
}

.offcanvas {
    z-index: 1024;
    width: 240px !important ;
    background-color: #FAFBFC;
    border-radius: 0px 24px 24px 0 ;
}

.offcanvas .offcanvas-body .tab-links {
    display: block;
}

.offcanvas .nav-barnd img {
    max-width: 160px;
}

.header-home2 .bars i {
    color: #000;
}

.active-nav .navbar .bars i {
    color: #000;
}

.active-nav .language-select {
    color: #000;
}



@media (width <= 991px) {
    .nav {
        justify-content: center;
    }
    .nav-links {
        margin: 1rem 0;
    }
}

@media (width <= 768px) { 
    .nav {
        justify-content: space-between;
    }
    .nav .nav-links {
        display: none;
    }
    .nav-barnd img {
        max-width: 120px;
    }
    .bars i {
        display: block;
    }
    .offcanvas .offcanvas-body .nav-links {
        display: block;
        height: 100%;
    }
    .nav-links ul {
        flex-flow: column;
        height: 100%;
        align-items: flex-start;
    }
    .nav-links ul li a {
        color: #000;
        margin-bottom: 1.5rem;
    }
}